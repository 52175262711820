import logo from './logo.svg';
import './App.css';

import {useEffect, useRef, useState} from 'react';
import axios from 'axios';

import { ClipboardIcon } from '@heroicons/react/24/outline';

function App() {
  const emailRef = useRef(null);
  const [utmUrl, setUtmUrl] = useState('');
  const [viewer, setViewer] = useState('');
  const [builderBtnText, setBuilderBtnText] = useState('Create & Copy');
  const [isBuilderBtnActive, setBuilderBtnActive] = useState(false);
  const [notifyBtnText, setNotifyBtnText] = useState('Notify');
  const [isNotifyBtnActive, setNotifyBtnActive] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState({state: false, text: ''});

  useEffect(() => {
    setUtmUrl(utmUrl => viewer);
  }, [viewer]);

  const viewerWrap = event => {
    const tx = document.getElementById("viewer");
    tx.style.height = 0;
    tx.style.height = (tx.scrollHeight) + "px";
  };

  const copyUrl = event => {
    navigator.clipboard.writeText(utmUrl);
  };
  
  const builderFormChange = event => {
    const formElement = document.querySelector("form");
    const formData = new FormData(formElement);
    const exclude = ["term", "content"];

    let err = 0;

    for (const pair of formData.entries()) {
      if(pair[1].trim() === "" && exclude.indexOf(pair[0]) === -1){
        err++;
      }
    }
    
    setBuilderBtnText('Create & Copy');
    setBuilderBtnActive(err > 0 ? false : true);
  };

  const generateURL = () => {
    const formElement = document.querySelector("form");
    const formData = new FormData(formElement);

    let url = formData.get('url');
    url += '?';

    formData.delete('url');

    const params = [];

    for (const pair of formData.entries()) {
      if(pair[1] !== ""){
        params.push(pair[0] + '=' + pair[1]);
      }
    }

    let utm_link = url + params.join("&");
    setViewer(utm_link);
    setBuilderBtnText('Copied!');

    navigator.clipboard.writeText(utm_link);

    setTimeout(() => {
      viewerWrap();
    }, 100);

    window.dataLayer.push({
      event: 'url_created'
    });
  };

  const submitHandler = event => {
    event.preventDefault();
    generateURL();
  };

  const notifyHandleChange = event => {
    let isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailRef.current.value);
    setNotifyBtnActive(isEmailValid);
  };

  const notifyHandleClick = (event) => {
    event.preventDefault();

    let formUrl = 'https://script.google.com/macros/s/AKfycbwhuLVNA092d_KKugl9408IUv1d__q0Qzgmld41rjzbTGXKQ6L6vzQprxTxgvHEi_YJ/exec';

    setNotifyBtnText('Sending..');
    setNotifyBtnActive(false);

    axios({
      method: 'get',
      url: `${formUrl}?email_field=${emailRef.current.value}`
    })
    .then(r => {
      if(r.data.result === 'success'){
        setSuccess(true);
        setError({state: false, text: ''});
        setNotifyBtnActive(false);
        emailRef.current.value = '';
      }
      else{
        setError({state: true, text: 'Something went wrong. Please try again.'});
        setNotifyBtnActive(true);
      }

      setNotifyBtnText('Notify');
    });
  };
  
  return (
    <div className="App">
      <header className="py-5 border-b border-gray-200" >
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <nav className="relative z-50 flex justify-between">
            <div className="flex items-center md:gap-x-12">
              <img src={logo} className="App-logo" alt="logo" />
            </div>
          </nav>
        </div>
      </header>
      <main className="bg-white py-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 md:max-w-none lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            <div className="relative text-left py-0 lg:py-12 md:py-0">
              <h2 className="text-5xl font-bold text-gray-600">Free UTM Builder</h2>
              <p className="mt-4 text-2xl font-normal text-gray-600">
                Utmfy's free UTM builder will generate a custom link to make tracking your marketing campaigns easier.
              </p>
              <p className="mt-6 text-lg text-gray-600">
                Track all your campaigns following these steps
              </p>
              <ol className='list-decimal mt-2 ml-6 text-sm text-gray-600'>
                <li>Enter the website URL that you want your visitors to land on.</li>
                <li>Fill out all the required fields with your values.</li>
                <li>Copy and use your campaign URL with UTM parameters.</li>
              </ol>
              <p className='mt-6 text-base font-semibold text-gray-600'>What are UTM links?</p>
              <p className="mt-4 text-sm font-normal text-gray-600">
                A UTM link, also known as a UTM parameter or UTM code, is a tracking URL that includes specific parameters added to the end of a regular URL. These parameters help marketers and website owners track the effectiveness of their marketing campaigns and understand user behavior by providing detailed information about the source of traffic.
              </p>
            </div>
            <div className="relative pl-0 lg:pl-4">
              <div className="space-y-12">
                <form onSubmit={submitHandler} onChange={builderFormChange} >
                  <div className="grid grid-cols-3 gap-3">
                    <div className="col-span-3">
                      <label htmlFor="url" className="block text-sm font-normal leading-6 text-gray-600 text-left">
                        URL<span className='text-red-500'>*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="url"
                          id="url"
                          autoComplete="given-name"
                          required
                          className="block w-full rounded-md border px-2 py-2 text-gray-900 shadow-sm border-gray-300 placeholder:text-gray-400 text-sm leading-6 outline-0"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label htmlFor="campaign" className="block text-sm font-normal leading-6 text-gray-600 text-left">
                        Campaign<span className='text-red-500'>*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="campaign"
                          id="campaign"
                          autoComplete="given-name"
                          placeholder='e.g. spring_sale'
                          required
                          className="block w-full rounded-md border px-2 py-2 text-gray-900 shadow-sm border-gray-300 placeholder:text-gray-400 text-sm leading-6 outline-0"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label htmlFor="source" className="block text-sm font-normal leading-6 text-gray-600 text-left">
                        Source<span className='text-red-500'>*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="source"
                          id="source"
                          autoComplete="given-name"
                          placeholder='e.g. google, instagram, linkedin'
                          required
                          className="block w-full rounded-md border px-2 py-2 text-gray-900 shadow-sm border-gray-300 placeholder:text-gray-400 text-sm leading-6 outline-0"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label htmlFor="medium" className="block text-sm font-normal leading-6 text-gray-600 text-left">
                        Medium<span className='text-red-500'>*</span>
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="medium"
                          id="medium"
                          autoComplete="given-name"
                          placeholder='e.g. cpc, banner, email'
                          required
                          className="block w-full rounded-md border px-2 py-2 text-gray-900 shadow-sm border-gray-300 placeholder:text-gray-400 text-sm leading-6 outline-0"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label htmlFor="term" className="block text-sm font-normal leading-6 text-gray-600 text-left">
                        Term
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="term"
                          id="term"
                          autoComplete="given-name"
                          placeholder='Identify the paid keywords'
                          className="block w-full rounded-md border px-2 py-2 text-gray-900 shadow-sm border-gray-300 placeholder:text-gray-400 text-sm leading-6 outline-0"
                        />
                      </div>
                    </div>
                    <div className="col-span-3">
                      <label htmlFor="content" className="block text-sm font-normal leading-6 text-gray-600 text-left">
                        Content
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="content"
                          id="content"
                          autoComplete="given-name"
                          placeholder='e.g. Use to differentiate ads'
                          className="block w-full rounded-md border px-2 py-2 text-gray-900 shadow-sm border-gray-300 placeholder:text-gray-400 text-sm leading-6 outline-0"
                        />
                      </div>
                    </div>

                    <div className="col-span-3 md:col-span-2 lg:col-span-2 mt-4">
                      <div className="relative rounded-md shadow-sm">
                        <textarea
                          name="viewer"
                          id="viewer"
                          value={viewer}
                          disabled
                          className="block w-full rounded-md border h-10 py-2 pl-3 pr-10 text-gray-900 border-gray-300 placeholder:text-gray-400 text-sm leading-6 outline-none resize-none overflow-hidden whitespace-pre-wrap"
                        ></textarea>
                        <div id="clipboard-btn" className="absolute inset-y-0 right-0 px-3 border-l flex items-center" onClick={copyUrl} >
                          <ClipboardIcon className="h-5 w-5 cursor-pointer" alt="" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 md:col-span-1 lg:col-span-1 mt-0 md:mt-4 lg:mt-4">
                      <button
                        type='submit'
                        disabled={!isBuilderBtnActive}
                        className={`flex w-full justify-center rounded-md bg-blue-800 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 opacity-60 ${isBuilderBtnActive ? 'active' : ''}`}
                      >
                        {builderBtnText}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </dl>
        </div>
      </main>
      <footer className="relative isolate overflow-hidden App-footer py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-2xl lg:text-center px-5 md:px-10 lg:px-10">
          <div className="max-w-2xl">
            <h2 className="text-3xl font-semibold tracking-tight text-white sm:text-4xl">Coming Soon ⏳</h2>
            <p className="mt-4 text-sm text-white font-normal">
              We work hard to make it easy and fun to manage your links.
              Please register to get notified when our new product is ready!
            </p>
            <div className='my-6 max-w-7xl px-8 lg:px-8'>
              <div className={`flex gap-x-4 ${isSuccess ? 'd-none' : ''}`}>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  ref={emailRef}
                  type="email"
                  className="min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6 outline-none"
                  onChange={notifyHandleChange}
                  id="email-address"
                  name="email"
                  autoComplete="email"
                  placeholder="Enter your email"
                />
                <button
                  onClick={notifyHandleClick}
                  className={`flex-none rounded-md cursor-pointer notify-button px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 ${isNotifyBtnActive ? 'active' : ''}`}
                  disabled={!isNotifyBtnActive}
                >
                  {notifyBtnText}
                </button>
              </div>
              <div className={`mt-2 flex gap-x-4 success ${!isSuccess ? 'd-none' : ''}`} >
                <span>Big thanks to you! We look forward to seeing you 🤩</span>
              </div>
              <div className={`mt-2 flex gap-x-4 error ${!isError.state ? 'd-none' : ''}`} >
                <span>{isError.text}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute left-0 top-0 -z-10 xl:-top-6 invisible sm:visible" aria-hidden="true">
          <svg width="312" height="481" viewBox="0 0 312 481" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="-50" cy="244.5" r="362" fill="#A8AAAE" fillOpacity="0.08"/>
            <circle cx="-50" cy="244.5" r="286" fill="#324FBE"/>
            <circle cx="-50" cy="244.5" r="219" fill="#A8AAAE" fillOpacity="0.08"/>
          </svg>
        </div>
        <div className="absolute right-0 top-0 -z-10 xl:-top-6 invisible sm:visible" aria-hidden="true">
          <svg width="312" height="481" viewBox="0 0 312 481" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="362" cy="244.5" r="362" fill="#A8AAAE" fillOpacity="0.08"/>
            <circle cx="362" cy="244.5" r="286" fill="#324FBE"/>
            <circle cx="362" cy="244.5" r="219" fill="#A8AAAE" fillOpacity="0.08"/>
          </svg>
        </div>
      </footer>
    </div>
  );
}

export default App;

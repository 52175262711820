import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { hydrateRoot, createRoot } from 'react-dom/client';
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: 'GTM-KS3L3FDP'
};

TagManager.initialize(tagManagerArgs);

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  const root = hydrateRoot(rootElement, <App />);
} else {
  const root = createRoot(rootElement);
  root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
